import React from "react"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import "./style.css"

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <img width="150" src={require('../assets/ht.png')} />
      <h1 className="title">Coming Soon</h1>
      <ul className="soc">
        <li className="soc-item">
          <a href="https://www.facebook.com/hangtuahid" className="soc-link" target="_blank" rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="14.7" height="26.1" viewBox="0 0 14.7 26.1">
              <path className="can-hover" fill="#a14a38" d="M9.7 9V6.3c0-.3.3-.6.6-.6h4.4V0H10C6.6 0 3.7 3.2 3.7 7.1v2H0v5.7h3.7v11.4h6V14.7h5V9h-5z"></path>
            </svg>
          </a>
        </li>
        <li className="soc-item">
          <a href="https://www.instagram.com/hangtuahid/" className="soc-link" target="_blank" rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="26.1" height="26.1" viewBox="0 0 26.1 26.1">
              <path className="can-hover" fill="#a14a38" d="M26.1 20.5c0 3.1-2.5 5.6-5.6 5.6H5.6c-3.1 0-5.6-2.5-5.6-5.6V5.6C0 2.5 2.5 0 5.6 0h14.9c3.1 0 5.6 2.5 5.6 5.6v14.9z"></path>
              <path fill="#FFF" d="M21 13.1c0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8 8 3.6 8 8M21.5 6.6c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2"></path>
            </svg>
          </a>
        </li>
        <li className="soc-item">
          <a href="mailto:marketing@hangtuah.co.id" className="soc-link">
            <svg xmlns="http://www.w3.org/2000/svg" width="26.1" height="26.1" viewBox="0 0 512 512">
              <g>
                <g>
                  <polygon fill="#a14a38" points="339.392,258.624 512,367.744 512,144.896 		"/>
                </g>
              </g>
              <g>
                <g>
                  <polygon fill="#a14a38" points="0,144.896 0,367.744 172.608,258.624 		"/>
                </g>
              </g>
              <g>
                <g>
                  <path fill="#a14a38" d="M480,80H32C16.032,80,3.36,91.904,0.96,107.232L256,275.264l255.04-168.032C508.64,91.904,495.968,80,480,80z"/>
                </g>
              </g>
              <g>
                <g>
                  <path fill="#a14a38" d="M310.08,277.952l-45.28,29.824c-2.688,1.76-5.728,2.624-8.8,2.624c-3.072,0-6.112-0.864-8.8-2.624l-45.28-29.856
                    L1.024,404.992C3.488,420.192,16.096,432,32,432h448c15.904,0,28.512-11.808,30.976-27.008L310.08,277.952z"/>
                </g>
              </g>
            </svg>
          </a>
        </li>
      </ul>
    </Layout>
  )
}

export default IndexPage
